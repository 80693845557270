<template>
   <div class="container">
      
      <div class="columns" v-if="!isEmailSent">
         <div class="row">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
               <form>
                  <div class="form-group" v-if="!$store.state.loggedIn">
                     <label for="inputEmail1">Email</label>
                     <input type="email" v-model="email" class="form-control" id="inputEmail1" aria-describedby="emailHelp" placeholder="Enter email" autofocus>
                  </div>

                  <div class="form-group mt-4" v-if="$store.state.loggedIn">
                     <p>For security, you must re-verify your email to reset your password. Click the button to continue.</p>
                  </div>

                  <b-list-group v-show="errors1.length > 0">
                     <b-list-group-item
                     v-for="error in errors1"
                     :key="error"
                     variant="danger"
                     >{{ error }}</b-list-group-item
                     >
                  </b-list-group>

                  <a href="reset-password" @click="$event.preventDefault(); sendEmail();" class="btn btn-orange btn-lg btn-block login-btn mt-5">Send Verify Link</a>
               </form>
            </div>
        </div>
      </div>

      <div class="columns" v-if="isEmailSent && !isSuccessfulReset">
         <div class="row">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
               <form>
                  <p class="mt-3">Check your email for the verification code, and paste it below. This code is required to reset the password for {{email}}.</p>
                  <div class="form-group">
                     <label for="inputVerificationCode">Verification Code</label>
                     <input type="text" v-model="verificationCode" class="form-control" id="inputVerificationCode1" placeholder="code" autofocus>
                  </div>

                  <div class="form-group">
                     <label for="inputPassword1">Password</label>
                     <input type="password" v-model="password1" class="form-control" id="inputPassword1" placeholder="password" autofocus>
                  </div>

                  <div class="form-group">
                     <label for="inputPassword1">Password (confirm)</label>
                     <input type="password" v-model="password2" class="form-control" id="inputPassword2" placeholder="repeat password" autofocus>
                  </div>

                  <b-list-group v-show="errors2.length > 0">
                     <b-list-group-item
                     v-for="error in errors2"
                     :key="error"
                     variant="danger"
                     >{{ error }}</b-list-group-item
                     >
                  </b-list-group>


                  <a href="reset-password" @click="$event.preventDefault(); resetPassword();" class="btn btn-orange btn-lg btn-block login-btn mt-5">Change Password</a>
               </form>
            </div>
        </div>
      </div>


      <div class="columns" v-if="isSuccessfulReset">
         <div class="row mt-5">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
               <p>Success: your password has been changed.</p>
            </div>
        </div>
      </div>


   </div>
</template>

<script>
export default {
   name: "SignUp",
   data() {
      return {
         emailAddress: "",
         verificationCode: "",
         isEmailSent: false,
         isVerified: false,
         password1: "",
         password2: "",
         errors1: [],
         errors2: [],
         isSuccessfulReset: false
      };
   },
   mounted() {
      window.gtag("event", "ResetPasswordPage");
      if (this.$store.state.loggedIn && !this.$store.state.isWallet)
      {
         this.email = this.$store.state.email;
      }
   },
   methods: {
      sendEmail() {
         const to_send = {
            email: this.email,
         };
         this.axios
            .post("/api/send-password-reset-email", to_send, { withCredentials: false })
            .then((response) => {
               console.log(response);

               if (response.data.status != "success") {
                  this.errors1.push(response.data.status);
                  return;
               }
               this.isEmailSent = true;
            })
            .catch((error) => {
               if (error.response) {
               this.errors1.push("Invalid entry; please check your inputs and try again.");
               } else if (error.message) {
               this.errors1.push("Something went wrong, please try again.");
               }
            });
      },
      resetPassword() {
         this.errors2 = [];
         if (this.password1 === "" || this.password2 === "") {
            this.errors2.push("The password is too short");
         }
         if (this.password1 !== this.password2) {
            this.errors2.push("The passwords must match");
         }
         const to_send = {
            verificationCode: this.verificationCode,
            password: this.password1,
         };
         if (this.errors2.length === 0) {
            this.axios
               .post("/api/change-password", to_send, { withCredentials: true })
               .then((response) => {
                  console.log(response);

                  if (response.data.status != "success") {
                     this.errors2.push(response.data.status);
                     return;
                  }
                  this.$store.commit("setAccountData", response.data.account_data);

                  this.isSuccessfulReset = true;

               setTimeout(() => {
                  window.location.assign("https://" + window.location.host);
               }, 2000);

               })
               .catch((error) => {
                  if (error.response) {
                     this.errors2.push("Invalid entry; please check your inputs and try again.");
                  } else if (error.message) {
                     this.errors2.push("Something went wrong, please try again.");
                  }
               });
         }
      },
      onReset() {},
   },
};
</script>
